<template>
  <div>
    <b-row class="content-header mb-2">
      <!-- Content Left -->
      <b-col class="content-header-left" cols="12" md="9">
        <breadcrumb />
      </b-col>

      <!-- Content Right -->
      <b-col class="content-header-right text-md-right" md="3" cols="12">
        <b-button
          @click="showFormModal(null)"
          class="px-3 text-capitalize"
          variant="primary"
        >
          <feather-icon size="16" icon="PlusIcon" class="mr-50" />
          ایجاد نقش
        </b-button>
      </b-col>
    </b-row>
    <div v-if="roles">
      <div class="d-flex justify-content-between align-items-center">
        <per-page v-model="perPage" class="w-50" />
        <search ref="adminsSearchComponent" class="w-50" v-model="searchInput" />
      </div>

      <b-card body-class="p-0" class="mt-1">
        <table-head
          ref="adminsTableHeadComponent"
          :items="tableHeadings"
          @sort="sort"
        />
        <b-row
          class="py-1 mx-1 border-bottom"
          v-for="role in roles"
          :key="role.id"
        >
          <b-col class="d-flex align-items-center pl-0">
            {{ role.name }}
          </b-col>
          <b-col class="">
        
            
             <b-badge class="ml-1 mt-1" v-for="permission in role.permissions" :key="permission.id" variant="primary">{{permission.name}}</b-badge>
         
          </b-col>
       
       
          <b-col
            class="d-flex align-items-center flex-row-reverse"
            style="direction: ltr"
          >
            {{ role.j_created_at }}
          </b-col>
          <b-col class="d-flex align-items-center">
            <b-button
              @click="showConfirmModal(role)"
              variant="flat-danger"
              class="font-weight-bolder"
            >
              <feather-icon icon="Trash2Icon" size="20" />
            </b-button>
            <b-button
              @click="showFormModal(role)"
              variant="flat-secondary"
              class="font-weight-bolder"
            >
              <feather-icon icon="Edit2Icon" size="20" />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <pagination
        ref="adminsPaginationComponent"
        v-model="page"
        :paginationData="paginationData"
      />
    </div>
    <b-sidebar
      width="500px"
      v-model="formModal"
      right
      backdrop
      shadow
      bg-variant="white"
    >
      <template #header>
        <div class="w-100">
          <div class="text-right">
            <b-button
              style="background-color: rgba(75, 75, 75, 0.12)"
              @click="formModal = false"
              variant="flat-dark"
              class="btn-icon rounded-circle mr-1"
            >
              <feather-icon icon="XIcon" size="20" />
            </b-button>
          </div>
          <div class="mr-1 sidebarHeaderTitleDiv">
            <h3 class="text-capitalize">
              {{ editMode ? "ویرایش نقش" : "ایجاد نقش" }}
            </h3>
          </div>
        </div>
      </template>
      <b-container class="">
        <b-form-group class="mt-3">
          <label for="role" class="text-capitalize"> نقش :</label>
           <b-form-input
            id="role"
            v-model="roleInput.name"
            type="text"
          />
        </b-form-group>
        <b-form-group class="mt-3">
          <label for="permissions" class="text-capitalize"> سطوح دسترسی : </label>
            <v-select
            ref="vSelect"
            id="admin_user"
            :components="{ OpenIndicator }"
            class="bg-white"
            :clearable="false"
            v-model="roleInput.permissions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :create-option="permission => ({ name: ''})"
            :reduce="permission => ({name: permission.name})"
            :options="permissions"
            label="name"
            taggable multiple
            placeholder="سطوح دسترسی را انتخاب کنید"
          >
          
          </v-select>
        </b-form-group>
      
      </b-container>
      <template #footer>
        <div class="d-flex align-items-center justify-content-end px-2 py-1">
          <b-button
            class="text-capitalize mr-2"
            @click="formModal = false"
            variant="outline-dark"
          >
            {{ $t("cancel") }}
          </b-button>
          <b-button variant="primary" @click="submitForm()">
            <div v-if="submitLoading" class="px-1">
              <b-spinner small style="width: 1.3rem; height: 1.3rem" />
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else class="text-capitalize">
              {{ $t("submit") }}
            </span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BAvatar,
  BButton,
  BSpinner,
  BSidebar,
  BContainer,
  BFormTextarea,
  BBadge
} from "bootstrap-vue";
import PerPage from "@/components/PerPage.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import Search from "@/components/Search.vue";
import TableHead from "@/components/TableHead.vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    PerPage,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    Breadcrumb,
    Pagination,
    Search,
    TableHead,
    BSpinner,
    BSidebar,
    BContainer,
    BFormTextarea,
    vSelect,
    BBadge
  },
  data() {
    return {
      perPage: 10,
      page: 1,
      users: [],
      permissions:[],
      roles: null,
      defaultAvatar: require("@/assets/images/avatars/default.png"),
      cancelToken: undefined,
      sortBy: "",
      sortDir: "",
      searchInput: "",
      paginationData: null,
      tableHeadings: [
        {
          title: "عنوان",
          slug: "name",
          sortable: true,
        },
        {
          title: "دسترسی ها",
          slug: "permissions",
          sortable: true,
        },
          {
          title: "تاریخ ایجاد",
          slug: "created_at",
          sortable: true,
        },
        {
          title: "actions",
          sortable: false,
        },
       
      ],

      OpenIndicator: {
        render: (createElement) =>
          createElement("feather-icon", { attrs: { icon: "ChevronDownIcon" } }),
      },
      companies: [],
      usersCancelToken: undefined,
      roleInput: {
       
        name: "",
        permissions:[]
      },
      roleId:"null",
      editMode: false,
      formModal: false,
      submitLoading: false,
    };
  },
  mounted() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
    this.getRoles();

    this.getPermissions()
  },
  computed:{
    
    
  },
  watch: {
    searchInput(value) {
      this.page = 1;
      this.getRoles();
    },
    perPage(value) {
      this.page = 1;
      this.getRoles();
    },
    page(value) {
      this.getRoles();
    },
  },
  methods: {
    filteredPermision(permissions){
         var filtered = []
         if(permissions.length>0){
      permissions.forEach(permission => {
        filtered.push({name:permission.name})
      })}
      return filtered
    },
    sort(args) {
      this.page = 1;
      this.sortBy = args.sortBy;
      this.sortDir = args.sortDir;
      this.getRoles();
    },

    async getRoles() {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        
      };

      const requestData = {
        method: "get",
        url: "/admin/roles",
        params: data,
        cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data;
          this.roles = data.data;
          this.paginationData = data.meta;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showConfirmModal(role) {
      this.$bvModal
        .msgBoxConfirm(
          `آیا از حذف نقش مطمئن هستید ؟  ${role.name}.`,
          {
            title: this.$t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("No"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (confirmed) => {
          if (confirmed) {
            let adminIndex = 0;
            // this.roles = await this.roles.filter((adminItem, index) => {
            //   if (adminItem.id == role.id) {
            //     adminIndex = index;
            //   }
            //   return adminItem.id !== role.id;
            // });

            const requestData = {
              method: "delete",
              url: `/admin/roles/delete/${role.id}`,
            };
            this.$http(requestData)
              .then((response) => {
                this.getRoles()
                this.$bvToast.toast(
                  `نقش "${role.name}"  با موفقیت حذف شد.`,
                  {
                    title: this.$t("Done"),
                    variant: "success",
                    solid: true,
                  }
                );
              })
              .catch((err) => {
                let message = this.$t("Unable to delete!");
                if (
                  err.response &&
                  err.response.data &&
                  err.response.data.message
                )
                  message = err.response.data.message;
                this.$bvToast.toast(message, {
                  title: this.$t("Error"),
                  variant: "danger",
                  solid: true,
                });
                this.roles.splice(adminIndex, 0, admin);
              });
          }
        });
    },
    showFormModal(role) {
      if (role) {
        this.roleId=role.id
        this.roleInput = {
        name: role.name,
        permissions:this.filteredPermision(role.permissions)
        };
        this.editMode = true;
      } else {
        this.roleId=""
        this.roleInput = {
         
          user_id: "",
          password: "",
          password_confirmation: "",
        };
        this.editMode = false;
      }
      this.formModal = true;
    },
    submitForm() {
      if (this.submitLoading) return;
      let errorMessage = null;
      if (!errorMessage && !this.roleInput.name) {
        errorMessage = "لطفا نام نقش را وارد کنید";
      }
      // if (!errorMessage && !this.roleInput.password) {
      //   errorMessage = "لطفا رمز را وارد کنید";
      // }
      // if (!errorMessage && this.roleInput.password.length < 8) {
      //   errorMessage = "رمز نمی تواند کمتر از 8 کاراکتر باشد";
      // }
      // if (!errorMessage && !this.roleInput.password_confirmation) {
      //   errorMessage = "لطفا تکرار رمز را وارد کنید";
      // }
      // if (
      //   !errorMessage &&
      //   this.roleInput.password != this.roleInput.password_confirmation
      // ) {
      //   errorMessage = "رمز و تکرار رمز یکسان نیستند";
      // }

      if (errorMessage) {
        this.$bvToast.toast(errorMessage, {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
        });
        return;
      }
      this.$refs.vSelect.isOptionSelected()
      console.log("this.$refs.vSelect",this.$refs.vSelect)
      console.log("this.roleInput",this.roleInput)
      this.submitLoading = true;

      const requestData = {
        method: this.editMode ? "put" : "post",
        url: this.editMode ? `/admin/roles/update/${this.roleId}` : `/admin/roles/create`,
        data: JSON.parse(JSON.stringify(this.roleInput)),
      };

      this.$http(requestData)
        .then((response) => {
          let message = "ادمین با موفقیت اضافه شد";
          if (this.editMode) message = "ادمین با موفقیت ویرایش شد";
          this.$bvToast.toast(message, {
            title: this.$t("Done"),
            variant: "success",
            solid: true,
          });
          if (!this.editMode && this.users && this.users.length > 0) {
            this.$refs.adminsPaginationComponent.clear();
            this.$refs.adminsSearchComponent.clear();
            this.$refs.adminsTableHeadComponent.clear();
            this.page = 1;
            this.searchInput = "";
            this.sortBy = "";
            this.sortDir = "";
          }

          this.submitLoading = false;
          this.formModal = false;
          this.getRoles();
        })
        .catch((err) => {
          let message = "خطا در ایجاد نقش";
          if (this.editMode) message = "خطا در ویرایش ادمین";
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;

          this.$bvToast.toast(message, {
            title: this.$t("Error"),
            variant: "danger",
          });
          this.submitLoading = false;
          console.log(err);
        });
    },
    getUsers(search, loading) {
      // if (typeof this.usersCancelToken != typeof undefined) {
      //   this.usersCancelToken.cancel(
      //     "Operation canceled due to new request."
      //   );
      // }
      //Save the cancel token for the current request
      // this.usersCancelToken = this.$http.CancelToken.source();

      let data = {
        search: search,
        per_page: 10,
        page: 1,
      };

      const requestData = {
        method: "get",
        url: "/admin/users/get_all_users",
        params: data,
        // cancelToken: this.usersCancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data;
          this.users = data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
   getPermissions(){
    if (typeof this.usersCancelToken != typeof undefined) {
        this.usersCancelToken.cancel(
          "Operation canceled due to new request."
        );
      }
      //Save the cancel token for the current request
      this.usersCancelToken = this.$http.CancelToken.source();

      let data = {
       
      };

      const requestData = {
        method: "get",
        url: "/admin/permissions",
        params: data,
        cancelToken: this.usersCancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data;
          this.permissions = data.data;
        })
        .catch((err) => {
          console.log(err);
        });
   }
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
